import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PokemonCard from "../components/PokemonCard.jsx";
import fleximonLogo from "../assets/fleximon-logo.png";

const HomePageComp = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  background-color: #fefefe;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: lightgray;
  height: 70px;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    height: 150px;
  }
`;

const Logo = styled.img`
  position: absolute;
  left: 100px;
  height: 50px;

  @media only screen and (max-width: 1200px) {
    left: 50px;
  }

  @media only screen and (max-width: 600px) {
    position: relative;
    left: 0;
  }
`;

const PokemonSearchInput = styled.input`
  height: 40px;
  width: 30%;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 10px;
    height: 30px;
    padding: 5px;
  }

  @media only screen and (max-width: 600px) {
    width: 200px;
    font-size: 12px;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;

  > * {
    margin: 0 10px;
    margin-bottom: 20px;
  }
`;

function Home() {
  const [pokemons, setPokemons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchPokemons = async () => {
      try {
        const response = await fetch('https://fleximons-api.jtiagodev.workers.dev/fleximon');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPokemons(data.results || []);
        setLoading(false);
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPokemons();
  }, []);

  
const Button = styled.button`
background-color: #009565;  /* Green color */
color: white;
border: none;
padding: 8px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
cursor: pointer;
border-radius: 8px;
transition: background-color 0.3s, transform 0.2s;

& > a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

&:hover {
  background-color: #45a049;  /* Slightly darker green */
  transform: scale(1.05);
}

&:active {
  background-color: #388e3c;  /* Darker green when clicked */
  transform: scale(0.98);
}
`;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPokemons = pokemons.filter(pokemon => 
    pokemon.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <HomePageComp>
      <Header>
        <Logo style={{ height: '90px' }} src={fleximonLogo} alt="Fleximon Logo" />
        <a href="https://ekjq6cxpza6.typeform.com/to/uxotJioR" target="_blank" rel="noopener noreferrer"><Button>📦 Encomendar</Button></a>
     {/*    <PokemonSearchInput
          onChange={handleSearch}
          value={searchTerm}
          placeholder="Enter pokemon name..."
        /> */}
      </Header>
      <CardContainer>
        {filteredPokemons.map((pokemon) => (
          <PokemonCard 
            key={pokemon.name} 
            pokemon={pokemon}
          />
        ))}
      </CardContainer>
    </HomePageComp>
  );
}

export default Home; 