import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TypePlaque from "./TypePlaque";
import typeToColor from "../shared/typeToColor";

const PokemonCardComp = styled.div`
  position: relative;
  width: 210px;
  border: 1px solid #000;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  background-color: white;
  transition: box-shadow 0.2s ease;
  overflow: hidden;
  box-shadow: 2px 2px 1px 1px #ccc;

  :hover {
    border: 1px solid
      ${(props) => (props.type ? typeToColor(props.type) : "#aaa")};
    box-shadow: 0px 0px 10px 5px
      ${(props) => (props.type ? typeToColor(props.type) : "#aaa")};
  }

  > p {
    margin: 0;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
  }
`;

const PokeImage = styled.img`
  width: 100%;
  padding: 10px;
  padding-top: 25px;
  box-sizing: border-box;
  background-color: white;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border-top: 1px solid #000;
  background-color: rgb(245, 245, 245);
`;

const Name = styled.h1`
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 18px;
`;

const Types = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

const LoadingCard = styled(PokemonCardComp)`
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


function formatToThreeDigits(number) {
  return number.toString().padStart(3, '0');
}

const PokemonCard = ({ pokemon }) => {
  const [pokemonDetails, setPokemonDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPokemonDetails = async () => {
      try {
        const response = await fetch(pokemon.url);
        const data = await response.json();
        setPokemonDetails(data);
      } catch (error) {
        console.error('Error fetching pokemon details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPokemonDetails();
  }, [pokemon.url]);

  if (loading || !pokemonDetails) {
    return <LoadingCard>Loading...</LoadingCard>;
  }

  if (!pokemonDetails?.types?.[0]?.type) {
    console.error('Invalid types for Pokemon:', {
        id: pokemonDetails?.id,
        /* name: pokemonDetails?.name,
        types: pokemonDetails?.types */
    });
}

  return (
    <PokemonCardComp type={pokemonDetails.types[0].type.name}>
      <p style={{ fontSize: "15px", fontWeight: "bold" }}>#{formatToThreeDigits(pokemonDetails.id)}</p>
      <div style={{ marginTop: "5px" }}>
      <PokeImage
        src={pokemonDetails.sprites.front_default}
        alt={`${pokemonDetails.name} official artwork`}
      />
      </div>
      <Content>
        <Name>{pokemonDetails.name}</Name>
        <Types>
          {pokemonDetails.types.map((type, index) => (
            <TypePlaque key={pokemonDetails.id + index}>
              {type.type.name}
            </TypePlaque>
          ))}
        </Types>
      </Content>
    </PokemonCardComp>
  );
};

export default PokemonCard; 